import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import Text from './Text';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage } from './images/DynamicImage';
import { Quiz } from 'types/quiz';
import Tracking from 'utils/tracking';

interface Item {
  question: string;
  answer: string[];
}

interface PeopleAskUsProps {
  title: string;
  list: Item[];
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: left;
  background: #fff;
  padding: 3rem 1rem;
  @media ${tablet} {
    padding: 2.25rem 1 rem;
  }
`;

const QuestionContainer = styled.div<{ expanded: boolean }>`
  overflow: ${({ expanded }) => (expanded ? 'auto' : 'hidden')};
  ::-webkit-scrollbar {
    width: 0;
  }
  -webkit-tap-highlight-color: transparent;
  transition: max-height 0.6s ease;
  border-radius: 0;
  background-color: #f0f0f0 !important;
  cursor: pointer;
  padding: 0.75rem 1rem 0;
  border-bottom: 1.341px solid rgba(115, 137, 156, 0.5);

  // Remove the top border by default
  border-top: none;

  // Apply border-radius for the first and last containers
  &:first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 1.25rem 1rem 0;
  }
  &:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 0.75rem 1rem 0.25rem;
    margin-bottom: 0.25rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

const Title = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.25rem;
  max-width: 28.75rem;
  width: 100%;
  margin-bottom: 1.5rem;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 1rem;
  }
`;

const Question = styled(Text)<{ expanded?: boolean }>`
  transition: color 0.2s ease;
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  max-width: 23.25rem;
  width: 100%;
  @media ${mobile} {
    max-width: 16.5625rem;
  }
`;

const List = styled.div`
  max-width: 28.75rem;
  width: 100%;
  div:last-child {
    border-bottom: none;
`;

const AnswerContainer = styled.div<{ expanded: boolean; maxHeight?: string }>`
  transition: max-height 0.4s ease;
  margin-top: 0.75rem;
  color: #000;
  font-size: 0.875rem;
  ::-webkit-scrollbar {
    width: 0;
    display: none;
  }
`;

const Answer = styled(Text)<{ expanded: boolean }>`
  padding-bottom: 1.5rem;
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const BulletPoint = styled.li<{ expanded: boolean }>`
  padding-bottom: 1.5rem;
  -webkit-text-size-adjust: 100%;
  max-width: 704px;
  font-size: 1.25rem;
  display: flex;
  color: #1c1c28;
  align-items: start;
  @media ${mobile} {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  &:before {
    content: '•'; /* Custom bullet */
    margin-right: 8px;
  }
`;

const StyledImage = styled(DynamicImage)`
  width: 100%;
  max-width: 540px;
  padding-bottom: 1.5rem;
`;

const PeopleAskUs: FC<PeopleAskUsProps> = ({ title, list }) => {
  const [expandedQuestions, setExpandedQuestions] = useState<number[]>([]);
  const [rotatedIcons, setRotatedIcons] = useState<{
    [index: number]: boolean;
  }>({});
  const refs = useRef<(HTMLDivElement | null)[]>([]);

  const childRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useQuery();
  const quiz = new URLSearchParams(location.search).get('qz') ?? Quiz.Main;

  const handleQuestionClick = (index: number, item: Item) => {
    handleClick(index);
    Tracking.logEvent({
      event: 'FAQ-clicks',
      funnel: quiz,
      widget: item.question,
    });
  };

  const handleClick = useCallback((index: number) => {
    setExpandedQuestions(prevState =>
      prevState.includes(index)
        ? prevState.filter(item => item !== index)
        : [...prevState, index],
    );
    setRotatedIcons(prev => ({ ...prev, [index]: !prev[index] }));
  }, []);

  useEffect(() => {
    if (!childRef.current) return;
  }, [childRef, expandedQuestions]);

  useEffect(() => {
    // Adjust heights for all expanded questions
    refs.current.forEach((ref, index) => {
      if (ref && expandedQuestions.includes(index)) {
        // Apply calculated maxHeight for expanded items
        ref.style.maxHeight = `${ref.scrollHeight}px`;
      } else if (ref) {
        // Reset maxHeight for collapsed items
        ref.style.maxHeight = '0px';
      }
    });
  }, [expandedQuestions, list.length]);

  const answerRender = (item, expanded) => {
    switch (item.type) {
      case 'text':
        return (
          <>
            {item.text.map(t => (
              <Answer
                color="dark100"
                expanded={expanded}
                key={t}
                dangerouslySetInnerHTML={{ __html: t }}
              />
            ))}
          </>
        );
      case 'bullet_point':
        return (
          <ul>
            {item.text.map(t => (
              <BulletPoint
                color="dark100"
                expanded={expanded}
                key={t}
                dangerouslySetInnerHTML={{ __html: t }}
              />
            ))}
          </ul>
        );
      case 'image':
        return <StyledImage alt="image" src={item.imageUrl} />;

      default:
        break;
    }
  };

  const renderItem = (item: Item, index: number) => {
    const key = `ask-us-question-${index}`;
    const expanded = expandedQuestions.includes(index);
    const isRotated = !!rotatedIcons[index];

    return (
      <QuestionContainer
        key={key}
        onClick={() => handleQuestionClick(index, item)}
        expanded={expanded}
      >
        <TitleContainer>
          <QuestionIconContiner>
            <MinusX />
            <MinusY rotate={isRotated} />
          </QuestionIconContiner>
          <Question>{item.question}</Question>
        </TitleContainer>

        <AnswerContainer
          ref={el => (refs.current[index] = el)}
          expanded={expanded}
        >
          <div ref={childRef}>
            {item.answer.map(answer => answerRender(answer, expanded))}
          </div>
        </AnswerContainer>
      </QuestionContainer>
    );
  };

  return (
    <Container>
      <Title>{title}</Title>
      <List>{list.map(renderItem)}</List>
    </Container>
  );
};

export default React.memo(PeopleAskUs);

const QuestionIconContiner = styled.div`
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.341px solid #ef71a0;
  border-radius: 50%;
  position: relative; /* Ensure the absolute positioning is relative to this container */
  cursor: pointer;
`;

const MinusX = styled.div`
  width: 17px;
  height: 1px;
  background-color: #ef71a0;
`;

const MinusY = styled.div<{ rotate?: boolean }>`
  height: 17px;
  width: 1px;
  background-color: #ef71a0;
  transform: ${({ rotate }) => (rotate ? 'rotate(90deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
  position: absolute; // Adjust positioning as needed
`;
