import orangeBubble from 'assets/icons/results/orange-bubble.svg';
import listIcon from 'assets/icons/results/list-icon.svg';
import resultsCardSvg from 'assets/icons/results/results-card-svg.svg';
import resultsCardSvgMob from 'assets/icons/results/results-card-svg_mob.svg';
import arrowsUp from 'assets/icons/results/arrows-up.svg';
import gender from 'assets/icons/results/gender.svg';
import age from 'assets/icons/results/age.svg';
import personality from 'assets/icons/results/personality.svg';
import favActivity from 'assets/icons/results/fav-activity.svg';

export default {
  orangeBubble,
  listIcon,
  resultsCardSvg,
  resultsCardSvgMob,
  arrowsUp,
  gender,
  age,
  personality,
  favActivity,
};
